.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(192, 192, 192, 0.35);
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 30px;
    width: 100vw;
    max-width: 280px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 1px silver;
}

.logo {
    object-fit: contain;
    max-width: 270px;
    margin-bottom: 20px;
}

.span-alert {
    color: #b72126;
    font-size: 14px;
    font-weight: 800;
}

.container-input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    width: 95%;
    background-color: white;
    border-radius: 6px;
    border: 1px solid silver;
}

.input {
    display: flex;
    flex: 1;
    padding: 3px;
    border: none;
    background: white;
    outline: none;
    font-size: 15px;
    font-weight: 500;
}

.version {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: gray;
}