.loading-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    height: 100vh;
    width: 100vw;
    /* background-color: rgba(255, 255, 255, 0.6); */
    background-color: transparent;
    align-items: center;
    justify-content: center;   
}

.loading-titulo {
    font-size: 16px;
    font-weight: bold;
    color: darkslategrey;
}