.pc-container {
    display: flex;
    align-items: center;
    cursor: default;
    margin-bottom: 0px;
    cursor: pointer;
}

.pc-line {
    margin-top: 8px;
    height: 1px;
    background-color: gainsboro;
    border: none;
}
