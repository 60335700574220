.tela-geral-container {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 90%;
    max-width: 800px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 1); 
    border-radius: 10px;
    border: 1px solid silver;
    height: 60%;   
    outline: none;
}

.tela-geral-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.tela-geral-topo {
    display: flex;
    align-items: center;
    border-bottom: 1px solid silver;
}

.tela-geral-topo-titulo {
    display: flex;
    flex: 1;
    height: 100%;
    justify-items: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: darkslategrey;
}

.tela-geral-topo-botoes {
    display: flex;
    height: 100%;
    justify-items: center;
    align-items: center;
    margin-bottom: 5px;
}

.tela-geral-topo-botao {
    border-radius: 50px;
    padding: 5px;
    margin-left: 15px;
    color: gray;
}
.tela-geral-topo-botao:hover {
    cursor: pointer;
    background: rgb(220, 220, 220, 0.6);
}

.tela-geral-main {
    display: flex;
    margin-top: 10px;
    overflow: auto;
}