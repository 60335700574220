.header-content {
    display: flex;
    position: sticky;
    top: 0px;
    padding : 15px 10px 15px 10px; /* topo | direita | inferior | esquerda */    
    align-items: center;
    background-color: #FFF;
    border-bottom: 1px solid gainsboro;
}

.header-user {
    display: flex;
    flex: 1;
    gap: 8px;
    color: "darkslategrey";
    font-size: 14px;
    font-weight: 700;
}

.header-exit {
    display: flex;
}

.header-exit-button {
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 5px 13px 5px 13px; /* topo | direita | inferior | esquerda */
    color: #FFF;
    font-size: 16px;
    background-color: #b72126;
}