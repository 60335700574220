.home-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
}

.home-filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.home-day-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 15px;
    gap: 10px;
}

.home-day-filter-button {
    cursor: pointer;
    padding: 13px;
    background-color: #FFF;
    border-radius: 20px;
    border: 1px solid gainsboro;
    font-size: 14px;
    font-weight: bold;
    min-width: 60px;
    margin-bottom: 10px;
}
.home-day-filter-button-pressed {
    cursor: pointer;
    padding: 13px;
    background-color: #b72126;
    border: 1px solid #b72126;
    color: #FFF;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    min-width: 60px;
    margin-bottom: 10px;

}

.home-day-filter-divider {
    margin-top: 0px;
    max-width: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border-right: 3px solid gray; 
}

.home-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    overflow-y: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}

.home-list-item {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 15px;
    gap: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 2px 2px 1px silver;
}

.home-list-hour {
    font-size: 28px;
    font-weight: bold;
    color:#b72126;
}

.home-list-detail {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.home-list-detail > span {
    /* flex: 1; */
}

.home-os-modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    padding: 5px;
    gap: 10px;
}

.home-os-modal-details {
    display: flex;
    font-size: 14px;
    flex: 1;
    flex-direction: row;
    color: slategray;
    gap: 10px;
}

.home-os-modal-detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 3px;
}

.home-os-modal-detail-titulo {
    font-size: 12px;
    flex: 1;
    font-weight: 500;
}

.home-os-modal-detail-valor {
    font-size: 13px;
    font-weight: 700;
}