.page-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #ededed;
    overflow-x: hidden;
}

.page-flex-1-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1500px;
}
